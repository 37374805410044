import * as _usr_const from '../config/usr-constant';
import * as _debug from '../helper/debug';
import axios from 'axios';

/**
 * setFormData
 * 
 */
export function setFormData(formData: any, data: any): any {
  let results: any = {};
  for (const key in formData) {
    if (data.hasOwnProperty(key)) {
      if (data[key] === null || data[key] === '' || data[key] === '0001-01-01T00:00:00Z') {
        if (data[key] === null || data[key] === '0001-01-01T00:00:00Z') {
          results[key] = null;
        } else {
          results[key] = '';
        }
      } else {
        results[key] = data[key];
      }
    }
    if (key === 'pickers') {
      results['pickers'] = formData[key];
      for (const picker in formData[key]) {
        if (data.hasOwnProperty(picker)) {
          if (data[picker] === null || data[picker] === '0001-01-01T00:00:00Z') {
            results['pickers'][picker] = null;
          } else {
            results['pickers'][picker] = data[picker];
          }
        }
      }
    }
  }
  return results;
}

/**
 * setValue
 * 
 */
type setValueProps = {
  formData: any,
  name: string,
  value: any,
  setFormData: (formData: any) => void
}
export function setValue({ formData, name, value, setFormData }: setValueProps): void {
  let tmpFormData: any = Object.assign({}, formData);
  if (tmpFormData[name] !== undefined && value !== undefined) {
    tmpFormData[name] = value;
    setFormData({ ...tmpFormData });
  }
}

/**
 * handleSubmit
 * 
 */
type handleSubmitParams = {
  action: string;
  method?: 'post' | 'put' | 'delete' | 'patch';
  formData: { [key: string]: any };
  history?: any;
  callbackSuccess?: any;
  callbackError?: any;
  backActionName?: string;
  setOverlayProppress: any;
  setFlashMessageSuccess: any;
  setFlashMessageError: any;
  setValidateErrors?: any;
  successFlashMessage?: string;
  errorFlashMessage?: string;
  redirectLogin?: boolean;
}
export function handleSubmit({
  action,
  method = 'post',
  formData,
  history,
  callbackSuccess,
  callbackError,
  backActionName,
  setOverlayProppress,
  setFlashMessageSuccess,
  setFlashMessageError,
  setValidateErrors,
  successFlashMessage = '保存しました',
  errorFlashMessage = '保存できませんでした',
  redirectLogin = true
}: handleSubmitParams) {

  window.scrollTo(0, 0);

  setOverlayProppress(true);

  const tmpBackActionName: string = backActionName !== undefined ? backActionName : '';

  let data = new URLSearchParams();
  if (Object.keys(formData).length > 0) {
    Object.keys(formData).forEach((inputName: string) => {
      let thisInputSet: boolean = true;
      if (inputName === 'pickers') {
        thisInputSet = false
      }
      if (typeof formData[inputName] === 'undefined') {
        thisInputSet = false
      }
      if (thisInputSet) {
        if (typeof formData[inputName] === 'object') {
          if (formData[inputName] !== null && Object.keys(formData[inputName]).length > 0) {
            Object.keys(formData[inputName]).forEach((key: any) => {
              data.append(inputName + '[]', formData[inputName][key]);
            }); 
          }
        } else {
          data.append(inputName, formData[inputName]);
        }
      }
    });
  }
  
  axios({
    method,
    url: _usr_const.ApiUrl + action,
    data
  })
    .then((response: any) => {
      setFlashMessageSuccess(successFlashMessage);
      if (tmpBackActionName !== '' && history !== undefined) {
        history.push(tmpBackActionName);
      }
      if (callbackSuccess !== undefined) {
        callbackSuccess(response);
      }
    })
    .catch((error) => {
      _debug.debugAxiosError(error);
      let errorMessage: string[] = [];
      if (error.response) {
        // set error message
        if (error.response.data.message !== undefined) {
          const errorJson = error.response.data.message;
          try {
            const parseErrorJson = JSON.parse(errorJson);
            if (typeof parseErrorJson === 'object' &&  parseErrorJson.length > 0) {
              parseErrorJson.forEach((message: string) => {
                errorMessage.push(message);
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
        // auth error
        if (error.response.status === 401 && redirectLogin === true) {
          window.location.href = '/users/login';
        }
        // validate error
        if (error.response.status === 400 && setValidateErrors !== undefined) {
          setValidateErrors(formatValidateError(error));
        }
      }
      // set flash message
      if (errorMessage.length > 0) {
        setFlashMessageError(errorMessage.join('¥n'));
      } else {
        setFlashMessageError(errorFlashMessage);
      }
      // console.log(error.config);
      if (callbackError !== undefined) {
        callbackError(error);
      }
    })
    .finally(() => {
      setOverlayProppress(false);
    });
}

/**
 * formatISO8601
 * 
 */
export function formatISO8601(date: Date): string {
  const offset = (function (d) {
    var o = d.getTimezoneOffset() / -60;
    return ((0 < o) ? '+' : '-') + ('00' + Math.abs(o)).substr(-2) + ':00';
  })(date);
  return [
    [
      date.getFullYear(),
      ('00' + (date.getMonth() + 1)).substr(-2),
      ('00' + date.getDate()).substr(-2),
    ].join('-'),
    'T',
    [
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ].join(':'),
    offset
  ].join('');
}

/**
 * formatDate
 * 
 */
export function formatDate(date: Date): string {
  return [
    [
      date.getFullYear(),
      ('00' + (date.getMonth() + 1)).substr(-2),
      ('00' + date.getDate()).substr(-2),
    ].join('-')
  ].join('');
}

/**
 * formatDateTime
 * 
 */
export function formatDateTime(date: Date): string {
  return [
    [
      date.getFullYear(),
      ('00' + (date.getMonth() + 1)).substr(-2),
      ('00' + date.getDate()).substr(-2),
    ].join('-'),
    ' ',
    [
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ].join(':')
  ].join('');
}

/**
 * handleDatePickerChange
 * 
 */
export function handleDatePickerChange(formData: any, date: any, name: string) {
  let tmpFormData: any = Object.assign({}, formData);
  let dateObj = null;
  if (date !== null) {
    dateObj = new Date(date);
    tmpFormData[name] = formatISO8601(dateObj);
  } else {
    tmpFormData[name] = dateObj;
  }
  tmpFormData['pickers'][name] = dateObj;
  return tmpFormData;
}

/**
 * setDatePickerValue
 * 
 */
export type setDatePickerValueProps = {
  formData: any;
  date: any;
  name: string;
  type: "date" | "datetime" | "iso" | string;
}
export function setDatePickerValue({
  formData,
  date,
  name,
  type = 'iso'
}: setDatePickerValueProps): any {
  let tmpFormData: any = Object.assign({}, formData);
  let dateObj = null;
  if (typeof tmpFormData[name] !== 'undefined') {
    if (date !== null) {
      dateObj = new Date(date);
      if (type === 'date') {
        tmpFormData[name] = formatDate(dateObj); 
      }
      if (type === 'datetime') {
        tmpFormData[name] = formatDateTime(dateObj); 
      }
      if (type === 'iso') {
        tmpFormData[name] = formatISO8601(dateObj); 
      }
    } else {
      tmpFormData[name] = dateObj;
    } 
  }
  if (typeof tmpFormData['pickers'][name] !== 'undefined') {
    tmpFormData['pickers'][name] = dateObj; 
  }
  return tmpFormData;
}


/**
 * formatValidateError
 * 
 * @param error 
 */
export function formatValidateError(error: any): { [key: string]: string[] } {
  let errors: { [key: string]: string[] } = {};
  if (error.response.data.errors !== undefined && typeof error.response.data.errors === 'object') {
    Object.keys(error.response.data.errors).forEach((name: any) => {
      // set error message
      const errorMessage: string[] = Object.values(error.response.data.errors[name]);
      errors[name] = errorMessage;
    });
  }
  return errors;
}

/**
 * checkUploadCsvFile
 * 
 * @param event 
 */
type checkUploadCsvFileResponce = {
  file: any;
  er: boolean;
  errorMessage: string[];
}
export function checkUploadCsvFile(event: any): checkUploadCsvFileResponce {
  let results: checkUploadCsvFileResponce = {
    file: {},
    er: false,
    errorMessage: []
  }
  const file_size_limit = 20971520;
  if (event === undefined || event.target === undefined || event.target.files[0] === undefined) {
    results.errorMessage.push('ファイル対象を取得できません');
  } else {
    results.file = event.target.files[0];
    if (results.file.length === 0) {
      results.errorMessage.push('ファイルを読み込めません');
    } else {
      //check file format
      var file_types = ['text/csv', 'application/vnd.ms-excel'];
      if (file_types.indexOf(results.file.type) === -1) {
        results.errorMessage.push('CSVファイルではありません');
      }
      //check file size
      if (results.file.size > file_size_limit) {
        results.errorMessage.push('インポート出来るファイルサイズは20MBまでです');
      }
    }
  }
  if (results.errorMessage.length > 0) {
    results.er = true;
  }
  return results;
}

/**
 * getFormEventNameValue
 * 
 * @param event 
 */
export function getFormEventNameValue(event: any): { [key: string]: any } {

  if (typeof event.target === 'undefined') {
    return {
      '': ''
    }
  }

  let inputName: string = '';
  if (typeof event.target.name !== 'undefined') {
   inputName = event.target.name; 
  }

  let type: string = '';
  let value: any = '';
  if (typeof event.target.type !== 'undefined') {
    type =  event.target.type;
  }
  if (type === "checkbox") {
    if (typeof event.target.checked !== 'undefined') {
      value = event.target.checked;
    }
  } else {
    if (typeof event.target.value !== 'undefined') {
      value = event.target.value; 
    }
  }
  // change boolean value
  if (value === 'true') {
    value = true;
  }
  if (value === 'false') {
    value = false;
  }
  
  return {
    [inputName]: value
  };
}